import React from "react";
import ReactDOM from "react-dom";

import { Title, Text, Container, Button, Overlay, createStyles, Flex } from '@mantine/core';
import { IconBrandApple, IconBrandUbuntu, IconBrandWindows } from "@tabler/icons-react";

const useStyles = createStyles((theme) => ({
    wrapper: {
        position: 'relative',
        paddingTop: 80,
        paddingBottom: 50,

        '@media (max-width: 765px)': {
            paddingTop: 80,
            paddingBottom: 50,
        },
    },

    video: {
        width: 600,
        '@media (max-width: 765px)': {
            width: '100%'
        },
        boxShadow: '0 0 150px 0px #e0ff7957',

    },

    sideHero: {
        position: 'relative',
        zIndex: 1,
        [theme.fn.smallerThan(400)]: {
            width: '100%'
        },
    },

    title: {
        fontWeight: 800,
        fontSize: 40,
        letterSpacing: -1,
        paddingLeft: theme.spacing.md,
        paddingRight: theme.spacing.md,
        color: theme.white,
        marginBottom: theme.spacing.xs,
        textAlign: 'center',
        fontFamily: `Greycliff CF, ${theme.fontFamily}`,

        '@media (max-width: 765px)': {
            fontSize: 28,
            textAlign: 'left',
        },
    },

    highlight: {
        color: theme.colors[theme.primaryColor],
    },

    description: {
        color: theme.colors.gray[0],
        textAlign: 'center',

        '@media (max-width: 765px)': {
            fontSize: theme.fontSizes.md,
            textAlign: 'left',
        },
    },

    downloadSection: {
        marginTop: theme.spacing.xl * 1.5,
        display: 'flex',
        justifyContent: 'center',
        paddingLeft: theme.spacing.md,
        paddingRight: theme.spacing.md,

        '@media (max-width: 765px)': {
            flexDirection: 'column',
        },
    },

    downloadButton: {
        height: 42,
        fontSize: theme.fontSizes.md,

        '&:not(:first-of-type)': {
            marginLeft: theme.spacing.md,
        },

        '@media (max-width: 765px)': {
            '&:not(:first-of-type)': {
                marginTop: theme.spacing.md,
                marginLeft: 0,
            },
        }
    },

}));

export default function HeroSection() {

    const { classes, cx } = useStyles();

    return (
        <div className={classes.wrapper}>
            {/* <Overlay color="#000" opacity={0.65} zIndex={1} /> */}


            <Flex
                align="center"
                justify="center"
                direction="row"
                wrap="wrap"
                rowGap="lg"
                columnGap="lg"
            >
                <video
                    className={classes.video}
                    autoPlay
                    loop
                    playsInline
                    muted
                    poster="https://s3.eu-north-1.amazonaws.com/torrentparty.io/torrent-party-hero-2023-thumbnail.png"
                >
                    {/* <source src="https://s3.eu-north-1.amazonaws.com/torrentparty.io/torrent_party_video_hero_web.mp4" type="video/mp4" /> */}
                    <source src="https://s3.eu-north-1.amazonaws.com/torrentparty.io/torrent-party-hero-2023.mp4" type="video/mp4" />
                </video>

                <div className={classes.sideHero}>
                    <Title className={classes.title}>
                        Watch torrents{' '}
                        <Text component="span" inherit className={classes.highlight}>
                            together
                        </Text>
                    </Title>

                    <Container size={640}>
                        <Text size="lg" className={classes.description}>
                            Create a virtual movie night with your friends and family, no matter where they are in the world. Chat with each other while watching, making it feel like you're all in the same room.
                            Download Torrent Party app and start hosting movie nights!
                        </Text>
                    </Container>

                    <div className={classes.downloadSection}>
                        <Button
                            leftIcon={<IconBrandWindows size={24} />}
                            className={classes.downloadButton}
                            variant="gradient"
                            size="lg"
                            sx={(theme) => ({
                                height: 40,
                                backgroundImage: theme.fn.gradient({ from: '#00A4EF', to: '#7FBA00', deg: 45 }),
                                color: theme.white,
                            })}
                            onClick={() => { window.open("https://torrent-party-prod-updates.s3.eu-north-1.amazonaws.com/Torrent Party Setup 0.2.17.exe") }}
                        >
                            Download for Windows (.exe)
                        </Button>
                        <Button
                            leftIcon={<IconBrandUbuntu size={24} />}
                            className={cx(classes.downloadButton)}
                            size="lg"
                            variant="gradient"
                            sx={(theme) => ({
                                height: 40,
                                backgroundImage: theme.fn.gradient({ from: '#77216F', to: '#E95420', deg: 45 }),
                                color: theme.white,
                            })}
                            onClick={() => { window.location.href = "https://torrent-party-prod-updates.s3.eu-north-1.amazonaws.com/torrent-party_0.2.17_amd64.deb" }}
                        >
                            Download for Linux (.deb)

                        </Button>
                        <Button
                            leftIcon={<IconBrandApple size={24} />}
                            className={cx(classes.downloadButton)}
                            size="lg"
                            variant="gradient"
                            sx={(theme) => ({
                                height: 40,
                                backgroundImage: theme.fn.gradient({ from: '#007AFF', to: '#A53ED5', deg: 45 }),
                                color: theme.white,
                            })}
                            onClick={() => { window.location.href = "https://torrent-party-prod-updates.s3.eu-north-1.amazonaws.com/Torrent Party-0.2.17.dmg" }}
                        >
                            Download for MacOS (.dmg)

                        </Button>
                    </div>
                </div>
            </Flex>
        </div>
    );
}
