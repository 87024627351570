import React from "react";
import FeaturesSection from "../components/FeaturesSection";
import FooterSection from "../components/FooterSection";

import HeaderSection from "../components/HeaderSection";
import HeroSection from "../components/HeroSection"
import LandingFAQSection from "../components/LandingFAQSection";
import SupportSection from "../components/SupportSection";

export default function MainPage(){
  return (
    <div>
      <HeaderSection/>
      <HeroSection/>
      <SupportSection/>
      <FeaturesSection/>
      <LandingFAQSection/>
      <FooterSection />
    </div>
  )
}