import React from "react";
import ReactDOM from "react-dom";

import MainPage from "./pages/MainPage"

import { MantineProvider } from '@mantine/core';


function App() {

    // let styles = useStyles()

    return (
        <MantineProvider
            withGlobalStyles
            withNormalizeCSS
            theme={{
                colorScheme: 'dark',
                
                // fontFamily: 'omnes-pro',
                // fontFamilyMonospace: 'omnes-pro',
                // headings: { fontFamily: 'omnes-pro'},

                fontFamily: 'Work Sans',
                fontFamilyMonospace: 'Work Sans',
                headings: { fontFamily: 'Work Sans'},

                fontSizes: {
                    xs: 12,
                    sm: 15,
                    md: 18,
                    lg: 21,
                    xl: 25,
                },
                primaryColor: 'salad',
                defaultGradient: {
                    from: '#9ac217',
                    to: '#f81010',
                    deg: 45,
                },
                colors: {
                    salad: ['#E0FF79'],
                    almostBlack: ['#202020'],
                    white: ['#FFF'],
                    darkText: ['#000'],
                },

                globalStyles: (theme) => ({
                    a: {
                        color: theme.colors.salad[0]
                    }
                }),
        
                // shadows: {
                //     md: '1px 1px 3px rgba(0, 0, 0, .25)',
                //     xl: '5px 5px 3px rgba(0, 0, 0, .25)',
                // },
        
                // headings: {
                //     fontFamily: 'Roboto, sans-serif',
                //     sizes: {
                //     h1: { fontSize: 30 },
                //     },
                // },
            }}
        >
            <MainPage />
        
        </MantineProvider>
    )
}

// let useStyles = makeStyles({
//     mainContainer: {
//         height: "100%"
//     }
// })

let appElement = document.getElementById("app");
ReactDOM.render(<App />, appElement);