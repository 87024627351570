import React from "react";
import ReactDOM from "react-dom";

import { Title, Text, Container, Button, Overlay, createStyles, Flex } from '@mantine/core';
import { IconBrandUbuntu, IconBrandWindows } from "@tabler/icons-react";

const useStyles = createStyles((theme) => ({
    wrapper: {

    },


}));

export default function FooterSection() {

    const { classes, cx } = useStyles();

    return (
        <Container p="md">
            <Text p="lg" align="center" color="dimmed" size="xs">Torrent Party is a fully automated platform that allows users to stream content online transmitted through the BitTorrent protocol. We do not make any changes to the content during transmission. Users initiate the uploading of torrent files, magnet URLs, content transmission, and sharing links to Torrent Party. As a platform, Torrent Party is not responsible for the content that is transmitted by users, nor any possible harm that may result from such content. However, if you come across any harmful or illegal content being transmitted through Torrent Party, please reach out to us immediately at contact.torrentparty@gmail.com. We take these issues seriously and will take necessary actions to block such content on our platform.</Text>
            <Text my="lg" size="md" align="center" className={classes.wrapper} color="gray">
                Torrent Party {new Date().getFullYear()}
            </Text>
        </Container>
    );
}