import { Card, Container, createStyles, SimpleGrid, Text, Title } from "@mantine/core"
import React from "react"
import { IconGauge, IconUser, IconCookie, IconMessages, IconCopy, IconBadgeCc} from '@tabler/icons-react';

const useStyles = createStyles((theme) => ({
    card: {
        border: `1px solid ${
          theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[1]
        }`,
      },
    
      cardTitle: {

        color: theme.colors[theme.primaryColor],
        
        '&::after': {
          content: '""',
          display: 'block',
          backgroundColor: theme.fn.primaryColor(),
          width: 45,
          height: 2,
          marginTop: theme.spacing.sm,
        },
      },
}))

const mockdata = [
    {
      title: 'Synchronized Playback',
      description:
        'Synchronized Playback allows you to watch movies with friends in perfect sync, ensuring a seamless viewing experience.',
      icon: IconCopy,
    },
    {
      title: 'Room Chat',
      description:
        'Discuss movies and express your emotions in real-time chat. Engage in movie discussions and share your thoughts as you watch.',
      icon: IconMessages,
    },
    {
      title: 'Subtitles',
      description:
        'Torrent Party automatically detects subtitles in torrents. You can also add subtitles from file.',
      icon: IconBadgeCc,
    },
  ];

export default function FeaturesSection() {
    const { classes, theme } = useStyles();

    const features = mockdata.map((feature) => (
        <Card key={feature.title} shadow="md" radius="md" className={classes.card} p="xl">
          <feature.icon size={50} stroke={2} color={theme.colors[theme.primaryColor][0]} />
          <Title size="h2" className={classes.cardTitle} mt="md">
            {feature.title}
          </Title>
          <Text size="md" color={theme.colors.white[0]}>
            {feature.description}
          </Text>
        </Card>
      ));

    return (
        <Container size="lg" py="xl">
            <SimpleGrid cols={3} spacing="xl" mt={50} breakpoints={[{ maxWidth: 'sm', cols: 1 }]}>
                {features}
            </SimpleGrid>            
        </Container>
    )    
}