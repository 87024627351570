import React from "react";
import { createStyles, Header, Group, ActionIcon, Container, Burger } from '@mantine/core';

const useStyles = createStyles((theme) => ({
  inner: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 80,
    
    [theme.fn.smallerThan('xs')]: {
      justifyContent: 'flex-start',
    },
  },
  logo: {
    fontWeight: 900,
    color: theme.colors.salad[0],
    [theme.fn.smallerThan(350)]: {
      display: 'none'
    },
  },

  logoSmall: {
    fontWeight: 900,
    color: theme.colors.salad[0],
    [theme.fn.largerThan(350)]: {
      display: 'none'
    },
  },

}));

export default function HeaderSection() {
  const { classes, cx } = useStyles();
  function Logo() {
    return (
      <div>
        <h1 className={classes.logo}>TORRENT PARTY <sup>β</sup></h1>
        <h1 className={classes.logoSmall}>TP <sup>β</sup></h1>
      </div>
    )
  }

  return (
    <Header height={80} >
      <Container className={classes.inner}>
        <Logo/>
      </Container>
    </Header>
  );
}