import { Accordion, Container } from "@mantine/core";
import React from "react";

export default function () {
    return (
        <Container size="lg">
            <h2>Frequently Asked Questions (FAQ)</h2>
            <Accordion variant="contained" multiple defaultValue={["howto-watch-torrents", "is-free", "hd-tv-shows"]} >
                <Accordion.Item value="howto-watch-torrents">
                    <Accordion.Control>
                        <h3>How to watch torrents together?</h3>
                    </Accordion.Control>
                    <Accordion.Panel>
                    With Torrent Party, you can create a virtual movie night with others no matter where they are in the world. Here's how to get started:
                    <ul>
                        <li>Download Torrent Party app and create an account.</li>
                        <li>Create a room for hosting your party and invite your friends by sharing the room code.</li>
                        <li>Add movies or TV shows to your party by pasting the magnet link into the app.</li>
                        <li>Chat with your friends in real-time while watching the movies or TV shows</li>
                    </ul>
                    Watching torrents together has never been easier with Torrent Party. So download the app today and start hosting your movie nights!
                    </Accordion.Panel>
                </Accordion.Item>
                <Accordion.Item value="is-free">
                    <Accordion.Control>
                        <h3>Is Torrent Party free to use?</h3>
                    </Accordion.Control>
                    <Accordion.Panel>Yes, Torrent Party is completely free to use. You can download the app and create a room for hosting movie nights with your friends without any charge. </Accordion.Panel>
                </Accordion.Item>
                <Accordion.Item value="hd-tv-shows">
                    <Accordion.Control>
                        <h3>Can I watch movies and TV shows with Torrent Party in high definition?</h3>
                    </Accordion.Control>
                    <Accordion.Panel>Yes, you can watch movies and TV shows with Torrent Party in high definition (HD). The quality depends on the source content added to the playlist.</Accordion.Panel>
                </Accordion.Item>
                <Accordion.Item value="how-many-people">
                    <Accordion.Control>
                        <h3>How many people can join a Torrent Party room?</h3>
                    </Accordion.Control>
                    <Accordion.Panel>There is no limit to the number of people who can join a Torrent Party room.</Accordion.Panel>
                </Accordion.Item>
                <Accordion.Item value="do-i-need-to-download">
                    <Accordion.Control>
                        <h3>Do I need to download the movies or TV shows beforehand?</h3>
                    </Accordion.Control>
                    <Accordion.Panel>
                        Once the movie is added to the playlist, it will be automatically downloaded by all participants in the room.
                        You can start watching without waiting for the download to complete fully. 
                    </Accordion.Panel>
                </Accordion.Item>
                <Accordion.Item value="fast-connection">
                    <Accordion.Control>
                        <h3>Do I need to have a fast internet connection to use Torrent Party?</h3>
                    </Accordion.Control>
                    <Accordion.Panel>
                    No, you don't need to have a fast internet connection to use Torrent Party, but it is recommended for the best experience.
                    It's recommended that the person hosting the party and those joining the party have a download speed that is faster than the playback speed of the video. This ensures that the video is downloaded and buffered in advance, providing a smoother and uninterrupted streaming experience.
                    </Accordion.Panel>
                </Accordion.Item>
                <Accordion.Item value="live-events">
                    <Accordion.Control>
                        <h3>Can I use Torrent Party to watch live events or sports games?</h3>
                    </Accordion.Control>
                    <Accordion.Panel>
                        No, the app is designed to stream movies and TV shows that are available on torrent networks. 
                        However, you can use Torrent Party to watch recordings of these events once they are available.
                    </Accordion.Panel>
                </Accordion.Item>
                <Accordion.Item value="suggest">
                    <Accordion.Control>
                        <h3>How to report bug or suggest feature for Torrent Party team?</h3>
                    </Accordion.Control>
                    <Accordion.Panel>
                        To report a bug or suggest a feature please join <a href="https://discord.gg/3kW6N3enXE" target="_blank">our Discord server</a>. 
                        There, you can find the latest information and announcements about Torrent Party, as well as ask questions and interact with the community.
                    </Accordion.Panel>
                </Accordion.Item>
            </Accordion>
        </Container>
    );
}
