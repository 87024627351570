import { Card, Container, createStyles, Flex, Group, Image, SimpleGrid, Text, Title } from "@mantine/core"
import React from "react"
import { IconGauge, IconUser, IconCookie, IconMessages, IconCopy, IconBadgeCc} from '@tabler/icons-react';
// @ts-ignore
import DiscordLogo from "../static/discord-logo-white.png"

const useStyles = createStyles((theme) => ({
  discordCard: {
    backgroundColor: "#5865F2"
  }
}))

export default function () {
    const { classes, theme } = useStyles();

    return (
        <Container size="md">

          <Flex direction="column" align="center" justify="center">
            <h2>Join <Text span color="#E0FF79">TorrentParty</Text> Community!</h2>
            <Flex direction="row" align="center" justify="center" gap="md" wrap="wrap">
              <a href="https://www.producthunt.com/posts/torrent-party?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-torrent&#0045;party" target="_blank"><img src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=383586&theme=light" alt="Torrent&#0032;Party - Netflix&#0032;Party&#0032;for&#0032;torrents | Product Hunt" style={{width: 250, height: 54}} width="250" height="54" /></a>
              <a target="_blank" href="https://discord.gg/3kW6N3enXE">
                <Card className={classes.discordCard} color="#5865F2" w={250} radius={10} p={20}>
                  <Image src={DiscordLogo} />
                </Card>
              </a>
            </Flex>
            <Text size="sm" my="md" align="center">For announcements, FAQs, bug reports and feature suggestions.</Text>
          </Flex>


            
        </Container>
    )    
}